import React, { useEffect, useReducer } from "react";
import { db } from "./api/firesbase";
import "./App.scss";

function App() {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "POSTS_LOADED":
          return { ...state, posts: action.posts };
        default:
          return state;
      }
    },
    {
      posts: []
    }
  );
  const { posts } = state;

  useEffect(() => {
    let blogRef = db.collection(`blog`);
    let isMounted = true;
    blogRef.onSnapshot(posts => {
      if (!isMounted) return;
      let finalArray = [];
      posts.forEach(doc => {
        finalArray.push(doc.data());
      });
      dispatch({ type: "POSTS_LOADED", posts: finalArray });
    });
    return () => (isMounted = false);
  }, []);

  return (
    <div className="outer_container">
      <nav>
        <a href="https://goodnewsgoats.com">Good News Goats</a>
      </nav>
      <div className="container">
        <h1>Goats Blog</h1>
        <ul>
          {posts.map(item => {
            const theDay = new Date(Number(item.date)).toLocaleDateString(
              "en-US"
            );
            return (
              <li key={item.key}>
                <article className="article">
                  <h3>{item.title}</h3>
                  <h5>{theDay}</h5>
                </article>
                <p>{item.body}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default App;
